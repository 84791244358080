import React from 'react'
import { PageHeader } from 'src/components/PageLayout/PageHeader'
import { FilterBar } from 'src/components/Reusable/IndexPage/FilterBar'
import { FilterBarConfigs } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { INDEX_PAGE_WITH_FILTERS } from 'src/components/Reusable/IndexPage/IndexPage.constants'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { HeaderBorder } from 'src/components/PageLayout/PageLayout.styles'
import { InfoSection } from 'src/components/Reusable/IndexPage/InfoSection'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import {
  bannerHeight,
  headerContentHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { useBannerContext } from 'src/context/Banner/BannerContext'
import { ActionRow, RiskContent, RiskNav } from '../risks.styles'
import { AddRiskModalBtn } from '../risks.components'
import { AddRiskModal } from '../modals/AddRiskModal'
import { AddManualRiskModal } from '../modals/AddManualRiskModal'
import { AddRiskCSVModal } from '../modals/AddRiskCSVModal'
import { RisksIndexGrid } from './RisksIndexGrid'

export const RisksIndexPage = (): JSX.Element => {
  const { track, events } = useAnalytics()
  const infoLink = HUBSPOT.RISK_REGISTER_OVERVIEW
  const { bannerCount } = useBannerContext()
  const top = headerContentHeight + bannerCount * bannerHeight
  return (
    <>
      <PageHeader title="Risk Register" subHeader={<HeaderBorder />} />
      <RiskContent>
        <RiskNav $top={top}>
          <ActionRow>
            <FilterBar
              config={FilterBarConfigs[INDEX_PAGE_WITH_FILTERS.RISKS]}
            />
            <AddRiskModalBtn
              text="Add Risk"
              handleClick={() => track(events.C_ADD_MANUAL_RISK)}
            />
          </ActionRow>
        </RiskNav>
        <InfoSection
          text="Track and manage risks to your company."
          linkText="Learn more"
          link={infoLink}
          isExternalLink
        />
        <RisksIndexGrid />
      </RiskContent>
      {/* ---- MODALS ---- */}
      <AddRiskModal />
      <AddManualRiskModal />
      <AddRiskCSVModal />
    </>
  )
}
