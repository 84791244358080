import { EroDarkEastSVG } from 'src/components/Icons/Brand'
import { GridHeader } from 'src/components/Reusable/Grid'
import styled, { css } from 'styled-components/macro'

export const RequestGridHeader = styled(GridHeader)<{
  $top: number
  $sticky?: boolean
}>`
  ${({ $top, $sticky }) => {
    return (
      $top &&
      $sticky &&
      css`
        position: sticky;
        top: ${$top}px;
        z-index: 2;
      `
    )
  }}
`

export const SuggestedEvidenceContainer = styled.div`
  display: flex;
  gap: 1em;
`

export const SuggestedEvidenceButtons = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuggestedEvidenceEroIcon = styled(EroDarkEastSVG)`
  height: 32px;
`
