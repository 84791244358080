import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Location } from 'history'
import queryString, { ParsedQuery } from 'query-string'
import Dropdown from 'react-bootstrap/Dropdown'
import { MenuPlaceholder } from 'src/components/Placeholders/MenuPlaceholder'
import isFunction from 'lodash/isFunction'
import { PERMISSIONS } from 'src/config/roleConfig'
import { ChevronSVG } from 'src/components/Icons/Basic'
import { NONE_ID } from 'src/Utils/globalConstants'
import { useNavigateContext } from '../../../Navigation/NavigateContext'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { CountChip } from '../../../Chips/BasicChip'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'
import { DropdownItemType } from './FilterDropdown.constants'
import {
  DropDownMenuWithNesting,
  FilterOptionContainer,
} from './FilterDropdown.styles'
import { NestedFilter } from './FilterDropdown.components'

type FilterDropdownBaseProps = {
  filterParam: FilterParam
  filterName: FilterName
  filterValues: DropdownItemType[]
  isLoading?: boolean
  hasNested?: boolean
  includeParentCondition?: boolean
}

type FilterDropdownConditionalProps =
  | {
      show?: boolean
      handleToggle?: () => void
    }
  | {
      show?: never
      handleToggle?: never
    }

type FilterDropdownProps = FilterDropdownBaseProps &
  FilterDropdownConditionalProps

export function FilterDropdown({
  filterParam,
  filterName,
  filterValues,
  isLoading,
  show,
  handleToggle,
  hasNested,
  includeParentCondition,
}: FilterDropdownProps): JSX.Element {
  const [activeSubmenu, setActiveSubmenu] = useState<string>(NONE_ID)
  const location = useLocation()
  const searchParamsFallback = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])
  const {
    navigateState: {
      parsedQueryParams,
      locationSearchString: contextLocationSearchString,
    },
  } = useNavigateContext()
  const searchParams = parsedQueryParams || searchParamsFallback
  const locationSearchString = contextLocationSearchString ?? location.search

  const appliedFilters: Record<string, string[]> = useMemo(() => {
    const appliedFilters: Record<string, string[]> = {}
    const preprocessed = searchParams[filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters[filterParam] = preprocessed
    } else {
      appliedFilters[filterParam] = preprocessed ? [preprocessed] : []
    }
    filterValues.forEach((value) => {
      if (value.nestedFilter && searchParams[value.nestedFilter.filterParam]) {
        if (Array.isArray(searchParams[value.nestedFilter.filterParam])) {
          appliedFilters[value.nestedFilter.filterParam] = searchParams[
            value.nestedFilter.filterParam
          ] as string[]
        } else {
          appliedFilters[filterParam] = [
            searchParams[value.nestedFilter.filterParam] as string,
          ]
        }
      }
    })
    return appliedFilters
  }, [searchParams, filterParam, filterValues])

  const isSmartCheckFilter = filterParam === FilterParam.AI_CONTROL_CHECKS

  return (
    <Dropdown
      autoClose="outside"
      show={show}
      onToggle={() => {
        setActiveSubmenu(NONE_ID)
        handleToggle?.()
      }}
    >
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        $isAi={isSmartCheckFilter}
        id={`${filterName}-dropdown`}
      >
        <p>{filterName}</p>
        {Object.values(appliedFilters).flat().length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {Object.values(appliedFilters).flat().length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      {isLoading ? (
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      ) : (
        <DropDownMenuWithNesting $hasNested={hasNested}>
          <DropdownItems
            filterParam={filterParam}
            filterValues={filterValues}
            location={location}
            searchParams={searchParams}
            appliedFilters={appliedFilters}
            activeSubmenu={activeSubmenu}
            setActiveSubmenu={setActiveSubmenu}
            locationSearchString={locationSearchString}
            includeParentCondition={includeParentCondition}
          />
        </DropDownMenuWithNesting>
      )}
    </Dropdown>
  )
}

export const DropdownItemComponent = ({
  filterParam,
  filterValue,
  appliedFilters,
  baseQuery,
  location,
  activeSubmenu,
  setActiveSubmenu,
  locationSearchString,
  includeParentCondition,
}: {
  filterParam: FilterParam
  filterValue: DropdownItemType
  appliedFilters: Record<string, string[]>
  baseQuery?: queryString.ParsedQuery<string>
  location: Location
  activeSubmenu: string
  setActiveSubmenu: (value: string) => void
  locationSearchString: string
  includeParentCondition?: boolean
}): JSX.Element => {
  const { navigateState } = useNavigateContext()
  const fallBackNavigate = useNavigate()
  const navigate = navigateState.navigate || fallBackNavigate

  const newAppliedFilters = new Set(appliedFilters[filterParam]?.map((f) => f))
  const isCustom = isFunction(filterValue.customOnClick)
  const isNestedFilterApplied =
    (filterValue.nestedFilter &&
      appliedFilters[filterValue.nestedFilter.filterParam]?.length > 0) ||
    false
  const isChecked =
    newAppliedFilters.has(filterValue.value) || isNestedFilterApplied
  isChecked
    ? newAppliedFilters.delete(filterValue.value)
    : newAppliedFilters.add(filterValue.value)

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...baseQuery,
        [filterParam]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  const nestedFilterHasValues =
    filterValue.nestedFilter && !!filterValue.nestedFilter.filterValues?.length

  return (
    <FilterOptionContainer>
      <FilterDropdownItem
        key={filterValue.value}
        replace
        to={href}
        onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          e.preventDefault()
          if (filterValue.nestedFilter && nestedFilterHasValues) {
            if (activeSubmenu != filterValue.nestedFilter.filterParam) {
              setActiveSubmenu(filterValue.nestedFilter.filterParam)
            } else {
              setActiveSubmenu(NONE_ID)
            }
            return
          }
          if (isCustom) {
            filterValue.customOnClick?.()
          } else {
            navigate(href, { replace: true })
          }
        }}
      >
        <CheckBoxInput
          id={filterValue.value}
          label={filterValue.label as React.ReactNode}
          checked={isChecked}
          onClick={(e) => {
            e.stopPropagation()
            if (isCustom) {
              filterValue.customOnClick?.()
              return
            }
          }}
          onChange={async (e) => {
            if (isCustom) {
              e.preventDefault()
              return
            }
            if (filterValue.nestedFilter) {
              setActiveSubmenu(NONE_ID)
              let selectAllQuery = {
                ...baseQuery,
                [filterValue.nestedFilter.filterParam]: Array.from(
                  filterValue.nestedFilter?.filterValues
                    .map((f) => f.value)
                    .filter((f) => f !== 'custom') || [],
                ),
              }
              if (includeParentCondition) {
                selectAllQuery = {
                  ...selectAllQuery,
                  [filterParam]: [filterValue.value],
                }
              }
              const selectAllHref = queryString.stringifyUrl(
                {
                  url: location.pathname,
                  query: selectAllQuery,
                },
                { arrayFormat: 'bracket' },
              )
              const deselectAllHref = queryString.exclude(
                `${location.pathname}${locationSearchString}`,
                [filterValue.nestedFilter.filterParam, filterParam],
                {
                  arrayFormat: 'bracket',
                },
              )
              const selectSelfHref = queryString.stringifyUrl(
                {
                  url: location.pathname,
                  query: {
                    ...baseQuery,
                    [filterParam]: [filterValue.value],
                  },
                },
                { arrayFormat: 'bracket' },
              )
              const deselectSelfHref = queryString.exclude(
                `${location.pathname}${locationSearchString}`,
                [filterParam],
                {
                  arrayFormat: 'bracket',
                },
              )
              if (!isChecked) {
                if (nestedFilterHasValues) {
                  navigate(selectAllHref, { replace: true })
                  setActiveSubmenu(filterValue.nestedFilter.filterParam)
                } else {
                  navigate(selectSelfHref, { replace: true })
                  setActiveSubmenu(NONE_ID)
                }
              } else {
                if (nestedFilterHasValues) {
                  navigate(deselectAllHref, { replace: true })
                } else {
                  navigate(deselectSelfHref, { replace: true })
                }
                setActiveSubmenu(NONE_ID)
              }
              return
            }
            navigate(href, { replace: true })
          }}
          tabIndex={-1}
          requiredPermissions={[PERMISSIONS.READ]}
        />
        {filterValue.nestedFilter && nestedFilterHasValues && (
          <ChevronSVG
            width="12"
            height="12"
            fill="fill.primary.dark"
            style={{
              transform: `rotate(${
                activeSubmenu == filterValue.nestedFilter?.filterParam
                  ? '180deg'
                  : '0'
              })`,
              margin: '8px',
            }}
          />
        )}
      </FilterDropdownItem>
      {!!filterValue.nestedFilter &&
        nestedFilterHasValues &&
        activeSubmenu == filterValue.nestedFilter.filterParam && (
          <NestedFilter
            filterParam={filterValue.nestedFilter.filterParam}
            filterValues={filterValue.nestedFilter.filterValues}
          />
        )}
    </FilterOptionContainer>
  )
}

export const DropdownItems = ({
  filterParam,
  filterValues,
  location,
  searchParams,
  appliedFilters,
  activeSubmenu,
  setActiveSubmenu,
  locationSearchString,
  includeParentCondition,
}: {
  filterParam: FilterParam
  filterValues: DropdownItemType[]
  location: Location
  searchParams: ParsedQuery
  appliedFilters: Record<string, string[]>
  activeSubmenu: string
  setActiveSubmenu: (value: string) => void
  locationSearchString: string
  includeParentCondition?: boolean
}): JSX.Element => (
  <>
    {filterValues.map((filterValue) => (
      <DropdownItemComponent
        key={filterValue.value}
        filterParam={filterParam}
        filterValue={filterValue}
        appliedFilters={appliedFilters}
        baseQuery={searchParams}
        location={location}
        activeSubmenu={activeSubmenu}
        setActiveSubmenu={setActiveSubmenu}
        locationSearchString={locationSearchString}
        includeParentCondition={includeParentCondition}
      />
    ))}
  </>
)
