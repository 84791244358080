import React, { useMemo } from 'react'
import { PageHeader } from 'src/components/PageLayout/PageHeader'
import { ModalFormId } from 'src/components/ModalForms'
import { IndexPage } from 'src/components/Reusable/IndexPage'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { useLocation } from 'react-router-dom'
import { InfoSection } from 'src/components/Reusable/IndexPage/InfoSection'
import { FilterBar } from 'src/components/Reusable/IndexPage/FilterBar'
import { INDEX_PAGE_WITH_FILTERS } from 'src/components/Reusable/IndexPage/IndexPage.constants'
import { showInfoToast } from 'src/Utils/helpers/toast'
import log from 'loglevel'
import { usePaginateItemsWithNavigate } from 'src/components/Reusable/paginate/paginate.hooks'
import { PaginateButtonsGroup } from 'src/components/Reusable/paginate/PaginateButtons'
import { Service } from 'src/xgenerated/service'
import { FilterBarConfigs } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { HeaderBorder } from 'src/components/PageLayout/PageLayout.styles'
import { useServices, useServicesRequest } from '../Services.hooks'
import { getActiveServices } from '../services.helpers'
import { ServicesIndexGrid } from './ServicesIndexGrid'

export const ServicesIndexPage = (): JSX.Element => {
  const location = useLocation()
  const req = useServicesRequest()
  const { data, isLoading, error } = useServices(req)

  if (error) {
    showInfoToast(
      "Sorry we couldn't load your services. Please refresh and try again.",
    )
    log.error('Error loading services', error)
  }

  const totalServices = data?.getTotal() || 0
  const activeServices = useMemo(() => {
    const services = data?.getServicesList() || []
    return getActiveServices(services, location.search)
  }, [data, location.search])

  const { paginatedItems, currentPage, nextPage, prevPage } =
    usePaginateItemsWithNavigate<Service>({
      items: activeServices,
      pageSize: 20,
    })

  return (
    <IndexPage
      pageHeader={
        <PageHeader
          title="Services"
          nowrap
          subHeader={<HeaderBorder />}
          customEl={
            activeServices.length > 20 ? (
              <PaginateButtonsGroup
                totalItems={activeServices.length}
                pageSize={20}
                currentPage={currentPage}
                onPrevClick={prevPage}
                onNextClick={nextPage}
              />
            ) : (
              <></>
            )
          }
        />
      }
      indexButtons={
        <StandardOpenModalButton
          modalId={ModalFormId.ADD_SERVICES}
          variant={StandardButtonVariant.PRIMARY}
          size={StandardButtonSize.SMALL}
          text="Add Services"
        />
      }
      filterBar={
        <FilterBar
          config={FilterBarConfigs[INDEX_PAGE_WITH_FILTERS.SERVICES]}
        />
      }
      infoSection={
        <InfoSection text="Track which services your company uses." />
      }
      indexGrid={
        <ServicesIndexGrid
          services={paginatedItems}
          isLoading={isLoading}
          totalServices={totalServices}
        />
      }
      noSubPageNav
    />
  )
}
