import { NOTIFICATION_TYPE } from '@trustero/trustero-api-web/lib/notification/notification_pb'
import log from 'loglevel'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetRoleAccessConfig } from 'src/app/AppAuth/AppAuth.hooks'
import { useReadonlyAccounts } from 'src/components/async/Account/account.hooks'
import { RoadmapAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { usePastAudit } from 'src/context/AuditContext'
import { useAuthContext, useIsReadOnlyUser } from 'src/context/authContext'
import { DATE_LIMIT_UPDATE_MESSAGE } from 'src/pages/AccountSettings/AccountSettings.constants'
import { useLatestNotification } from 'src/pages/AccountSettings/AccountSettings.hooks'
import { BannerState } from 'src/Utils/globalTypes'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'

export interface StatusResponse {
  status: {
    indicator: string
    description: string
  }
  page: {
    url: string
  }
}

const fetchStatus = async (): Promise<StatusResponse> => {
  const res = await fetch('https://status.trustero.com/api/v2/status.json')
  return res.json()
}

const emptyBannerState: BannerState = {
  showBanner: false,
  message: '',
  linkText: '',
  link: '',
  isWarning: false,
}

export const useAppStatusBanner = (isDismissed: boolean): BannerState => {
  const [bannerState, setBannerState] = useState<BannerState>(emptyBannerState)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const isDismissedRef = useRef(isDismissed)

  // Update ref when isDismissed changes
  useEffect(() => {
    isDismissedRef.current = isDismissed
  }, [isDismissed])

  const fetchAndSetStatus = useCallback(async () => {
    if (isDismissedRef.current) return

    try {
      const status = await fetchStatus()
      if (status.status.indicator !== 'none') {
        setBannerState({
          showBanner: true,
          message:
            'The application is currently experiencing issues. Please check the status page for more information.',
          linkText: 'Visit Status Page',
          link: status.page.url,
          isWarning: true,
        })
      } else {
        setBannerState(emptyBannerState)
      }
    } catch {
      setBannerState(emptyBannerState)
    }
  }, []) // No dependencies needed since we use ref

  useEffect(() => {
    if (isDismissed) {
      setBannerState(emptyBannerState)
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
      return
    }

    // Initial fetch
    fetchAndSetStatus()

    // Set up interval
    intervalRef.current = setInterval(fetchAndSetStatus, 60000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isDismissed, fetchAndSetStatus])

  return bannerState
}

export const useUserRoleBanner = (): BannerState => {
  const [bannerState, setBannerState] = useState<BannerState>(emptyBannerState)
  const { bannerConfig: userRoleBannerConfig } = useGetRoleAccessConfig()

  useEffect(() => {
    if (userRoleBannerConfig.showBanner) {
      setBannerState({
        ...emptyBannerState,
        showBanner: true,
        message: userRoleBannerConfig.bannerMessage,
        linkText: userRoleBannerConfig.linkText,
        link: userRoleBannerConfig.link,
        internalLink: userRoleBannerConfig.internalLink,
        isWarning: true,
      })
    } else {
      setBannerState(emptyBannerState)
    }
  }, [userRoleBannerConfig])

  return bannerState
}

export const useInPastAuditBanner = (): BannerState => {
  const [bannerState, setBannerState] = useState<BannerState>(emptyBannerState)
  const { isPastAudit, endDate } = usePastAudit()

  useEffect(() => {
    if (isPastAudit) {
      setBannerState({
        ...emptyBannerState,
        showBanner: true,
        message: `The end date of this audit was ${endDate}. Continuing to edit it may be confusing as new evidence won't appear, etc.`,
        link: HUBSPOT.AUDIT_PERIOD_DOCUMENTATION,
        isWarning: true,
      })
    } else {
      setBannerState(emptyBannerState)
    }
  }, [isPastAudit, endDate])

  return bannerState
}

export const useAccountPurposeBanner = (): BannerState => {
  const [bannerState, setBannerState] = useState<BannerState>(emptyBannerState)
  const isReadonly = useIsReadOnlyUser()
  const { authCtx } = useAuthContext()
  const { data: readonlyAccounts } = useReadonlyAccounts()

  const showAccountPurposeBanner = useMemo(
    () =>
      !isReadonly &&
      readonlyAccounts
        ?.getAccountsList()
        .map((ele) => ele.getId())
        .includes(authCtx.accountId),
    [isReadonly, readonlyAccounts, authCtx.accountId],
  )

  useEffect(() => {
    if (showAccountPurposeBanner) {
      setBannerState({
        showBanner: true,
        message:
          'You are currently in a Trustero Demo account. Any changes you make will be visible to all users.',
        isWarning: true,
      })
    } else {
      setBannerState(emptyBannerState)
    }
  }, [showAccountPurposeBanner])

  return bannerState
}

export const useDateLimitUpdateBanner = (): BannerState => {
  const [bannerState, setBannerState] = useState<BannerState>(emptyBannerState)
  const location = useLocation()
  const { data, error } = useLatestNotification({
    message: DATE_LIMIT_UPDATE_MESSAGE,
    type: NOTIFICATION_TYPE.BANNER,
  })

  if (error) {
    log.error('Error fetching notifications', error)
  }

  const showDateLimitBanner = useMemo(
    () =>
      !!data?.getNotification()?.getId() &&
      location.pathname.includes(RoadmapAbsoluteRoutes.INDEX),
    [data, location.pathname],
  )

  useEffect(() => {
    if (showDateLimitBanner) {
      setBannerState({
        showBanner: true,
        message:
          'The date limit was updated recently. This affects what you see in the Compliance Roadmap.',
        link: HUBSPOT.DATE_LIMIT,
        isWarning: true,
      })
    } else {
      setBannerState(emptyBannerState)
    }
  }, [showDateLimitBanner])

  return bannerState
}
