import React from 'react'
import { UserRoleBanner } from './UserRoleBanner'
import { InPastAuditBanner } from './InPastAuditBanner'
import { AccountPurposeBanner } from './AccountPurposeBanner'
import { AppStatusBanner } from './AppStatusBanner'

export const HeaderBanner = ({ mb = 0 }: { mb?: number }): JSX.Element => (
  <div style={{ marginBottom: `${mb}px` }}>
    <UserRoleBanner />
    <InPastAuditBanner />
    <AccountPurposeBanner />
    <AppStatusBanner />
  </div>
)
