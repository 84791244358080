import React, { useEffect, useState } from 'react'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import isFunction from 'lodash/isFunction'
import { FlexRow } from '../Flex'
import {
  NextIcon,
  PrevNext,
  PlainButton,
  PrevNextContainer,
} from '../prevNext/prevNext.styles'
import { getItemRange } from './paginate.helpers'

export type PaginateButtonsProps = {
  totalItems: number
  pageSize: number
}

export type PaginateButtonsGroupProps = PaginateButtonsProps & {
  currentPage: number
  onPrevClick: (() => void) | null
  onNextClick: (() => void) | null
}

export const PaginateButtonsGroup = ({
  totalItems,
  pageSize,
  currentPage,
  onPrevClick,
  onNextClick,
}: PaginateButtonsGroupProps): JSX.Element => {
  const [itemRange, setItemRange] = useState<string>('')
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (!totalItems) return
    if (totalItems !== total) {
      setTotal(totalItems)
    }
    const range = getItemRange(currentPage, pageSize, totalItems)
    if (range !== itemRange) {
      setItemRange(range)
    }
  }, [currentPage, pageSize, totalItems, total, itemRange])

  return (
    <PrevNextContainer>
      <PrevNext>
        {itemRange} of {total}
      </PrevNext>
      <FlexRow gap={32}>
        <PlainButton
          disabled={!isFunction(onPrevClick)}
          onClick={onPrevClick || (() => undefined)}
        >
          <NextIcon icon={faChevronLeft} disabled={!isFunction(onPrevClick)} />
        </PlainButton>
        <PlainButton
          disabled={!isFunction(onNextClick)}
          onClick={onNextClick || (() => undefined)}
        >
          <NextIcon icon={faChevronRight} disabled={!isFunction(onNextClick)} />
        </PlainButton>
      </FlexRow>
    </PrevNextContainer>
  )
}
