import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { BannerState } from 'src/Utils/globalTypes'
import {
  useAccountPurposeBanner,
  useAppStatusBanner,
  useDateLimitUpdateBanner,
  useInPastAuditBanner,
  useUserRoleBanner,
} from 'src/global.hooks/banners.hooks'

export enum BannerType {
  USER_ROLE = 'USER_ROLE',
  DATE_LIMIT_UPDATE = 'DATE_LIMIT_UPDATE',
  IN_PAST_AUDIT = 'IN_PAST_AUDIT',
  ACCOUNT_PURPOSE = 'ACCOUNT_PURPOSE',
  APP_STATUS = 'APP_STATUS',
}

type BannerContextType = {
  banners: Record<BannerType, BannerState>
  bannerCount: number
  dismissBanner: (type: BannerType) => void
}

const initialBannerState: BannerState = {
  showBanner: false,
  message: '',
  isDismissed: false,
}

const initialBanners: Record<BannerType, BannerState> = {
  [BannerType.USER_ROLE]: { ...initialBannerState },
  [BannerType.DATE_LIMIT_UPDATE]: { ...initialBannerState },
  [BannerType.IN_PAST_AUDIT]: { ...initialBannerState },
  [BannerType.ACCOUNT_PURPOSE]: { ...initialBannerState },
  [BannerType.APP_STATUS]: { ...initialBannerState },
}

export const BannerContext = createContext<BannerContextType>({
  banners: initialBanners,
  bannerCount: 0,
  dismissBanner: () => null,
})

export const BannerProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [banners, setBanners] =
    useState<Record<BannerType, BannerState>>(initialBanners)
  const [bannerCount, setBannerCount] = useState<number>(0)
  const dismissedBanners = useRef<Set<BannerType>>(new Set())

  // User Role Banner
  const userRoleBanner = useUserRoleBanner()

  // In Past Audit Banner
  const inPastAuditBanner = useInPastAuditBanner()

  // Account Purpose Banner
  const accountPurposeBanner = useAccountPurposeBanner()

  // Date Limit Update Banner
  const dateLimitUpdateBanner = useDateLimitUpdateBanner()

  // App Status Banner
  const appStatusBanner = useAppStatusBanner(
    dismissedBanners.current.has(BannerType.APP_STATUS),
  )

  // Memoize the banner update logic
  const updateBanners = useCallback(() => {
    const newBanners = { ...initialBanners }
    let count = 0

    const updateBanner = (type: BannerType, banner: BannerState) => {
      if (banner.showBanner && !dismissedBanners.current.has(type)) {
        newBanners[type] = banner
        count++
      }
    }

    updateBanner(BannerType.APP_STATUS, appStatusBanner)
    updateBanner(BannerType.USER_ROLE, userRoleBanner)
    updateBanner(BannerType.IN_PAST_AUDIT, inPastAuditBanner)
    updateBanner(BannerType.ACCOUNT_PURPOSE, accountPurposeBanner)
    updateBanner(BannerType.DATE_LIMIT_UPDATE, dateLimitUpdateBanner)

    setBanners(newBanners)
    setBannerCount(count)
  }, [
    appStatusBanner,
    userRoleBanner,
    inPastAuditBanner,
    accountPurposeBanner,
    dateLimitUpdateBanner,
  ])

  useEffect(() => {
    updateBanners()
  }, [updateBanners])

  const dismissBanner = useCallback((type: BannerType) => {
    dismissedBanners.current.add(type)
    setBanners((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isDismissed: true,
        showBanner: false,
      },
    }))
  }, [])

  return (
    <BannerContext.Provider
      value={{
        banners,
        bannerCount,
        dismissBanner,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}

export const useBannerContext = (): BannerContextType =>
  useContext(BannerContext)
