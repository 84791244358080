import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components/macro'

export const NestedDropdownMenu = styled.div.attrs({
  className: 'dropdown-menu',
})`
  position: absolute;
  left: 100%;
  top: 0;
  display: block;
`

export const FilterOptionContainer = styled.div`
  position: relative;
`

export const DropDownMenuWithNesting = styled(Dropdown.Menu)<{
  $hasNested: boolean
}>`
  ${({ $hasNested }) =>
    $hasNested &&
    `overflow: visible !important;
  max-height: fit-content !important;`}
`
