import {
  bannerHeight,
  filterBarHeight,
  headerContentHeight,
} from 'src/components/PageLayout/dimensionUtils'
import styled from 'styled-components/macro'
import { FlexAlign } from '../Flex'
import { GridColumn, GridHeader } from './Grid'

export const CenteredGridColumn = styled(GridColumn)`
  justify-content: center;
`

export const NoPaddingGridColumn = styled(GridColumn)`
  padding: 0px;
`

export const StickyGridHeader = styled(GridHeader).attrs(
  ({ $top, $bannerCount }: { $top?: number; $bannerCount: number }) => ({
    top: $top
      ? $top
      : headerContentHeight + filterBarHeight + $bannerCount * bannerHeight,
  }),
)<{ $gridAlign?: FlexAlign; $top?: number; $bannerCount?: number }>`
  position: sticky;
  z-index: 1;
  ${({ $gridAlign }) => {
    if (!$gridAlign) return ''
    return `justify-content: ${$gridAlign};`
  }}
`
