import React from 'react'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { useComplianceFrameworks } from 'src/components/async/complianceframework'
import { useComplianceFrameworkObjectives } from 'src/components/async/Framework/useComplianceFrameworkObjectives'
import { FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'
import { FilterName, FilterParam } from '../FilterBar.types'
import { NONE_VALUE } from '../FilterBar.constants'
import { FilterDropdown } from './FilterDropdown'
import { NestedFilterType } from './FilterDropdown.constants'
import { LoadingFilterDropdown } from './FilterDropdown.components'

const CfModelIdToFilterParam: Record<string, FilterParam> = {
  [FRAMEWORK_MODEL_IDS.SOC2]: FilterParam.SOC2,
  [FRAMEWORK_MODEL_IDS.SOC1]: FilterParam.SOC1,
  [FRAMEWORK_MODEL_IDS.ISO27001]: FilterParam.ISO,
  [FRAMEWORK_MODEL_IDS.ISO27701_DC]: FilterParam.ISO27701_DC,
  [FRAMEWORK_MODEL_IDS.ISO27701_DP]: FilterParam.ISO27701_DP,
  [FRAMEWORK_MODEL_IDS.HIPAA]: FilterParam.HIPAA,
  [FRAMEWORK_MODEL_IDS.HITRUST]: FilterParam.HITRUST,
  [FRAMEWORK_MODEL_IDS.NISTCSF]: FilterParam.NISTCSF,
  [FRAMEWORK_MODEL_IDS.NYDFS]: FilterParam.NYDFS,
  [FRAMEWORK_MODEL_IDS.PCISAQD]: FilterParam.PCISAQD,
  [FRAMEWORK_MODEL_IDS.PCISAQA]: FilterParam.PCISAQA,
  [FRAMEWORK_MODEL_IDS.PIPEDA_DC]: FilterParam.PIPEDA_DC,
  [FRAMEWORK_MODEL_IDS.PIPEDA_DP]: FilterParam.PIPEDA_DP,
  [FRAMEWORK_MODEL_IDS.TEST]: FilterParam.TEST,
}

export const ComplianceFrameworkFilterDropdown = (): JSX.Element | null => {
  const {
    data: cfData,
    isLoading: cfLoading,
    error: cfError,
  } = useComplianceFrameworks(true)

  const complianceFrameworks = cfData?.getItemsList() || []
  const cfIds = complianceFrameworks?.map((cf) => cf.getId())
  const {
    data: requirementsData,
    isLoading: requirementsLoading,
    error: requirementsError,
  } = useComplianceFrameworkObjectives(cfIds)
  if (cfError || requirementsError) return <></>
  if (cfLoading || requirementsLoading) {
    return <LoadingFilterDropdown filterName={FilterName.SOURCE} />
  }

  const cfFilterNameToNestedFilter: Record<string, NestedFilterType> = {}
  complianceFrameworks.forEach((cf) => {
    const requirements =
      requirementsData
        ?.getItemsList()
        .filter(
          (objective) => objective.getComplianceFrameworkId() === cf.getId(),
        ) || []
    if (requirements && requirements.length > 0) {
      const nestedFilter: NestedFilterType = {
        filterParam: CfModelIdToFilterParam[cf.getModelId()],
        filterValues: [
          NONE_VALUE,
          ...requirements.map((requirement) => ({
            value: requirement.getModelId(),
            label: requirement.getName(),
          })),
        ],
      }
      cfFilterNameToNestedFilter[cf.getName()] = nestedFilter
    }
  })

  const filterValues = complianceFrameworks.map((cf: ComplianceFramework) => ({
    value: cf.getModelId(),
    label: cf.getName(),
    nestedFilter: cfFilterNameToNestedFilter[cf.getName()],
  }))

  return complianceFrameworks.length > 0 ? (
    <FilterDropdown
      filterParam={FilterParam.COMPLIANCE_FRAMEWORK}
      filterName={FilterName.COMPLIANCE_FRAMEWORK}
      filterValues={filterValues}
      hasNested
    />
  ) : null
}
