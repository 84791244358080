import React from 'react'
import { useBannerContext, BannerType } from 'src/context/Banner/BannerContext'
import { Banner } from './Banner'

export const AppStatusBanner = ({
  noMargin,
}: {
  noMargin?: boolean
}): JSX.Element => {
  const { banners, dismissBanner } = useBannerContext()
  const appStatusBanner = banners[BannerType.APP_STATUS]

  if (!appStatusBanner?.showBanner) {
    return <></>
  }

  return (
    <Banner
      noMargin={noMargin}
      text={appStatusBanner.message}
      isWarning={appStatusBanner.isWarning}
      onDismiss={() => dismissBanner(BannerType.APP_STATUS)}
      linkText={appStatusBanner.linkText}
      link={appStatusBanner.link}
    />
  )
}
