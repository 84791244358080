import React from 'react'
import { GridRow } from 'src/components/Reusable/Grid'
import {
  bannerHeight,
  filterBarHeight,
  headerContentHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { useBannerContext } from 'src/context/Banner/BannerContext'
import { StyledRisksGridHeader } from '../risks.styles'
import { RISK_HEADERS } from '../risks.constants'

export const RisksGridHeader = ({
  hasSorting,
}: {
  hasSorting?: boolean
}): JSX.Element => {
  const { bannerCount } = useBannerContext()
  // TODO: Add sorting behavior here
  return hasSorting ? (
    <></>
  ) : (
    <GridRow>
      {RISK_HEADERS.map((header: { name: string; isCentered: boolean }) => (
        <StyledRisksGridHeader
          key={header.name}
          $top={
            headerContentHeight + filterBarHeight + bannerCount * bannerHeight
          }
          justifyContent={header.isCentered ? 'center' : 'flex-start'}
        >
          {header.name}
        </StyledRisksGridHeader>
      ))}
    </GridRow>
  )
}
