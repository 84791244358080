import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import isString from 'lodash/isString'
import isEqual from 'lodash/isEqual'
import { FilterParam } from '../IndexPage/FilterBar/FilterBar.types'
import { getPaginationLink } from './paginate.helpers'

export type PaginatedItemsType<T> = {
  paginatedItems: T[]
  currentPage: number
  totalPages: number
  nextPage: () => void
  prevPage: () => void
  goToPage: (page: number) => void
}

export type PaginatedItemsInputType<T> = {
  items: T[]
  pageSize: number
  initialPage: number
  onNext?: (currentPage: number, totalPages: number) => void
  onPrev?: (currentPage: number) => void
  onGoToPage?: (page: number, totalPages: number) => void
}

/**
 * This hook is used to paginate an array of items
 * @param items - The array of items to paginate
 * @param pageSize - The number of items per page
 * @returns paginatedItems - The paginated items
 * @returns currentPage - The current page
 * @returns totalPages - The total number of pages
 */
export const usePaginateItems = <T,>({
  items,
  pageSize,
  initialPage,
  onNext,
  onPrev,
  onGoToPage,
}: PaginatedItemsInputType<T>): PaginatedItemsType<T> => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  const totalPages = useMemo(
    () => Math.ceil(items.length / pageSize),
    [items, pageSize],
  )

  const paginatedItems = useMemo(() => {
    const start = (currentPage - 1) * pageSize
    return items.slice(start, start + pageSize)
  }, [items, currentPage, pageSize])

  const nextPage = useCallback(() => {
    if (onNext) {
      onNext(currentPage, totalPages)
    } else {
      setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))
    }
  }, [totalPages, onNext, currentPage])

  const prevPage = useCallback(() => {
    if (onPrev) {
      onPrev(currentPage)
    } else {
      setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
    }
  }, [onPrev, currentPage])

  const goToPage = useCallback(
    (page: number) => {
      onGoToPage?.(page, totalPages)
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page)
      }
    },
    [totalPages, onGoToPage],
  )

  useEffect(() => {
    goToPage(initialPage)
  }, [initialPage, goToPage])

  return useMemo(
    () => ({
      paginatedItems,
      currentPage,
      totalPages,
      nextPage,
      prevPage,
      goToPage,
    }),
    [paginatedItems, currentPage, totalPages, nextPage, prevPage, goToPage],
  )
}

export const usePaginateItemsWithNavigate = <T,>({
  items,
  pageSize,
}: Omit<PaginatedItemsInputType<T>, 'initialPage'>): PaginatedItemsType<T> => {
  const location = useLocation()
  const navigate = useNavigate()
  const prevParams = useRef<ParsedQuery<string>>({})
  const searchParams = useMemo(
    () =>
      queryString.parse(location.search, {
        arrayFormat: 'bracket',
      }),
    [location],
  )
  const initialPage = useMemo(() => {
    const pageParam = searchParams[FilterParam.PAGE]
    return isString(pageParam) ? Number(pageParam) : 1
  }, [searchParams])

  useEffect(() => {
    // get params minus page param
    const prevParamsCopy = { ...prevParams.current }
    const paramsCopy = { ...searchParams }
    delete prevParamsCopy[FilterParam.PAGE]
    delete paramsCopy[FilterParam.PAGE]
    if (!isEqual(paramsCopy, prevParamsCopy)) {
      const url = queryString.stringifyUrl(
        {
          url: location.pathname,
          query: {
            ...paramsCopy,
            [FilterParam.PAGE]: 1,
          },
        },
        {
          arrayFormat: 'bracket',
        },
      )
      prevParams.current = searchParams
      navigate(url)
    }
  }, [searchParams, location.pathname, navigate, prevParams])

  const onNext = useCallback(
    (currentPage: number, totalPages: number) => {
      if (currentPage >= totalPages) return
      const link = getPaginationLink(
        location.pathname,
        currentPage + 1,
        searchParams,
      )
      navigate(link)
    },
    [navigate, location.pathname, searchParams],
  )

  const onPrev = useCallback(
    (currentPage: number) => {
      if (currentPage <= 1) return
      navigate(
        getPaginationLink(location.pathname, currentPage - 1, searchParams),
      )
    },
    [navigate, location.pathname, searchParams],
  )

  return usePaginateItems({
    items,
    pageSize,
    initialPage,
    onNext,
    onPrev,
  })
}
