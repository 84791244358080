import React from 'react'
import { BannerType, useBannerContext } from 'src/context/Banner/BannerContext'
import { Banner } from './Banner'

export const UserRoleBanner = (): JSX.Element => {
  const { banners } = useBannerContext()
  const userRoleBanner = banners[BannerType.USER_ROLE]
  const showUserRoleBanner = userRoleBanner?.showBanner

  if (!showUserRoleBanner) return <></>

  return (
    <Banner
      linkText={userRoleBanner.linkText}
      link={userRoleBanner.link}
      internalLink={userRoleBanner.internalLink}
      text={userRoleBanner.message}
      isWarning={userRoleBanner.isWarning}
    />
  )
}
