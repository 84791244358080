import React from 'react'
import { useBannerContext } from 'src/context/Banner/BannerContext'
import { ControlsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import {
  bannerHeight,
  headerContentHeight,
  largeFilterBarHeight,
} from '../../../../components/PageLayout/dimensionUtils'
import { GridRow } from '../../../../components/Reusable/Grid'
import { getControlsListItemConfig } from '../../IndexItem/ControlsListItem.helpers'
import { ControlListItemConfig } from '../../IndexItem/ControlListItem.constants'
import { ControlsGridHeader } from './ControlsIndexGrid.styles'
import {
  ControlIndexGridChecksHeaders,
  ControlsIndexGridAssigneeHeader,
  ControlsIndexGridDepartmentHeader,
  ControlsIndexGridEvidenceHeader,
  ControlsIndexGridFrameworksHeader,
  ControlsIndexGridIdHeader,
  ControlsIndexGridNameHeader,
  ControlsIndexGridStatusHeader,
} from './ControlsIndexGrid.components'

export const ControlsIndexGridHeader = ({
  config,
}: {
  config?: ControlListItemConfig
}): JSX.Element => {
  // Account for Header + Filter height when used in Controls Page
  const { bannerCount } = useBannerContext()
  const isControlsPage = location.pathname.includes(
    ControlsAbsoluteRoutes.INDEX,
  )
  const { showScanStaleButton } = getControlsListItemConfig(config)
  const baseTop = isControlsPage
    ? headerContentHeight + largeFilterBarHeight + bannerCount * bannerHeight
    : 0
  const top = showScanStaleButton ? baseTop + 40 : baseTop

  const {
    showDepartmentColumn,
    showModelIdColumn,
    showControlNameColumn,
    showFrameworkColumn,
    showCheckColumns,
    showEvidenceColumns,
    showStatusColumn,
    showAssigneeColumn,
    showMenuColumn,
  } = getControlsListItemConfig(config)

  return (
    <GridRow>
      {showDepartmentColumn && <ControlsIndexGridDepartmentHeader top={top} />}
      {showModelIdColumn && <ControlsIndexGridIdHeader top={top} />}
      {showControlNameColumn && <ControlsIndexGridNameHeader top={top} />}
      {showFrameworkColumn && <ControlsIndexGridFrameworksHeader top={top} />}
      {showCheckColumns && <ControlIndexGridChecksHeaders top={top} />}
      {showEvidenceColumns && <ControlsIndexGridEvidenceHeader top={top} />}
      {showStatusColumn && <ControlsIndexGridStatusHeader top={top} />}
      {showAssigneeColumn && <ControlsIndexGridAssigneeHeader top={top} />}
      {showMenuColumn && <ControlsGridHeader top={top} />}
    </GridRow>
  )
}
