import React, { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import {
  color,
  compose,
  flexbox,
  FlexboxProps,
  margin,
  padding,
  textStyle,
  typography,
} from 'styled-system'
import palette from 'src/designSystem/variables/palette'

export const ShowPageContainer = styled.div.attrs(() => {
  return {
    pt: '32px',
    px: 'l',
    pb: 'l',
  }
})`
  ${padding}
  position: relative;
  height: 100%;
`
export const ShowPageHeaderContainer = styled.div.attrs({
  mb: 's',
})`
  ${margin}
  display: flex;
  flex-direction: column;
`

export const StyledShowPageHeader = styled.header`
  display: flex;
`

export const ShowPageHeaderBorderBottom = styled.div`
  border-bottom: 1px solid #acb3c3;
  width: calc(100% + 40px);
  margin: 20px -20px;
`

export const ShowPageHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin-right: 12px;
`

export const ShowPageBackArrow = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

export const RouteTitle = styled.div.attrs(() => {
  return {
    color: 'fill.neutral.base',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: '22px',
  }
})`
  &,
  & > * {
    ${compose(color, typography)}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  margin-bottom: 10px;

  a {
    text-decoration: none;
  }
  a:not(:hover) {
    ${color}
  }
`

export const ShowPageTitleWithIcon = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const ItemTitle = styled.h1.attrs({
  color: 'black',
  textStyle: 'header.2',
})`
  ${compose(color, textStyle)}
  height: 40px;
  line-height: 40px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ItemSubtitle = styled.h2.attrs({
  color: 'black',
  textStyle: 'header.3',
})`
  ${compose(color, textStyle)}
  height: 30px;
  line-height: 30px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ShowPageHeaderButtons = styled.div.attrs({
  mr: 'm',
})`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  > *:not(:last-child) {
    ${margin}
  }
  flex: 0;
`

export const ShowPageItem = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
`
export const ShowPageBody = styled.section`
  grid-column: span 12;
  display: flex;
  gap: 20px;
  width: 100%;
`

export const ShowPageItemDescription = styled.article.attrs({
  mb: 'm',
})`
  ${margin}
  grid-column: span 8;
`
export const ShowPageItemGuidanceAndNotes = styled.article.attrs({
  mb: 'm',
})`
  ${margin}
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const ShowPageItemStats = styled.article.attrs<{ children: ReactNode }>(
  ({ children }) => ({
    mb: 'm',
    children: <div className="stats-grid">{children}</div>,
  }),
)`
  ${margin}
  grid-column: 9 / -1;

  > .stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 4px 20px;
    align-items: center;
  }
`

export const ShowPageLinkagesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  padding: 0;
  gap: 16px;
  flex: 1 1 auto;
  min-width: fit-content;
`

export const ShowPageItemStatsRow = styled.div.attrs({
  className: 'show-page-item-info-col',
})`
  display: contents;
`

export const ShowPageItemStatsLabel = styled.div.attrs({
  className: 'show-page-item-info-col',
  color: 'text.med',
  textStyle: 'subtitle.2',
})`
  ${compose(color, textStyle)}
  grid-column: 1;
`

export const ShowPageItemStatsValue = styled.div.attrs({
  className: 'show-page-item-info-col',
  fontSize: 3,
})<FlexboxProps>`
  ${typography}
  grid-column: 2 / -1;
  display: flex;
  flex-wrap: wrap;
  ${flexbox}
  position: relative;
  word-break: break-word;
`

export const AlignedShowPageItemStatsValue = styled(ShowPageItemStatsValue)`
  align-items: center;
`

export const ShowPageItemBody = styled.article`
  grid-column: 1 / -1;
  position: relative;
`

export const ShowPageBanIcon = styled(FontAwesomeIcon)`
  color: ${palette.neutral[600]};
`

export const ShowPageSectionContainer = styled.div`
  margin-bottom: 3em;
`

export const ShowPageSectionHeader = styled.h6`
  color: ${palette.blue['700']};
  margin-bottom: 0;
`
