import React from 'react'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { BannerType, useBannerContext } from 'src/context/Banner/BannerContext'
import { Banner } from './Banner'

export const InPastAuditBanner = (): JSX.Element => {
  const { banners } = useBannerContext()
  const inPastAuditBanner = banners[BannerType.IN_PAST_AUDIT]
  const showInPastAuditBanner = inPastAuditBanner?.showBanner

  if (!showInPastAuditBanner) {
    return <></>
  }

  return (
    <Banner
      text={inPastAuditBanner.message}
      link={HUBSPOT.AUDIT_PERIOD_DOCUMENTATION}
    />
  )
}
