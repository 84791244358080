import queryString, { ParsedQuery } from 'query-string'
import { FilterParam } from '../IndexPage/FilterBar/FilterBar.types'

export const getPaginationLink = (
  path: string,
  page: number,
  searchParams: ParsedQuery<string>,
): string =>
  queryString.stringifyUrl(
    {
      url: path,
      query: {
        ...searchParams,
        [FilterParam.PAGE]: page,
      },
    },
    { arrayFormat: 'bracket' },
  )

export const getItemRange = (
  currentPage: number,
  pageSize: number,
  total: number,
): string => {
  const start = (currentPage - 1) * pageSize + 1
  const end = currentPage * pageSize
  if (end > total) {
    return `${start}-${total}`
  }
  return `${start}-${end}`
}
