import React from 'react'
import { ReactComponent as TuneIcon } from '../../../Icons/assets/tune-icon.svg'
import { FilterBarWrapper } from './FilterBar.styles'
import {
  AuditFilterDropdown,
  DepartmentFilterDropdown,
  HasDocumentationFilterDropdown,
  OwnerFilterDropdown,
  PolicyFilterDropdown,
  StatusFilterDropdown,
} from './FilterDropdowns'
import { ClearAllFilters } from './FilterBar.components'
import { FilterBarProps, FilterName, FilterParam } from './FilterBar.types'
import { RiskFilterDropdown } from './FilterDropdowns/RiskFilterDropdown'
import { ReceptorFilterDropdown } from './FilterDropdowns/ReceptorFilterDropdown'
import { ServiceRoleFilterDropdown } from './FilterDropdowns/ServiceRoleFilterDropdown'
import { RiskTierFilterDropdown } from './FilterDropdowns/RiskTierFilterDropdown'
import { AttestationFilterDropdown } from './FilterDropdowns/AttestationFilterDropdown'
import { SmartChecksFilterDropdown } from './FilterDropdowns/SmartChecksFilterDropdown'
import { SourceFilterDropdown } from './FilterDropdowns/SourceFilterDropdown'
import { DateRangeFilter } from './DateRangeFilter'
import { ControlFilterDropdown } from './FilterDropdowns/ControlFilterDropdown'
import { EvidenceTypeFilterDropdown } from './FilterDropdowns/EvidenceTypeFilterDropdown'
import { ComplianceFrameworkFilterDropdown } from './FilterDropdowns/ComplianceFrameworkFilterDropdown'

export const FilterBar = ({ config }: FilterBarProps): JSX.Element => (
  <FilterBarWrapper>
    <TuneIcon />
    {config.department && <DepartmentFilterDropdown />}
    {config.policy && <PolicyFilterDropdown />}
    {config.status && <StatusFilterDropdown />}
    {config.owner && <OwnerFilterDropdown />}
    {config.documentation && (
      <>
        <HasDocumentationFilterDropdown
          param={FilterParam.HAS_DOCUMENTATION}
          name={FilterName.HAS_DOCUMENTATION}
        />
        <HasDocumentationFilterDropdown
          param={FilterParam.IS_CUSTOMIZED}
          name={FilterName.IS_CUSTOMIZED}
        />
      </>
    )}
    {config.complianceFramework && <ComplianceFrameworkFilterDropdown />}
    {config.audit && <AuditFilterDropdown />}
    {config.risk && (
      <>
        <RiskFilterDropdown
          param={FilterParam.INHERENT_RISK}
          name={FilterName.INHERENT_RISK}
        />
        <RiskFilterDropdown
          param={FilterParam.RESIDUAL_RISK}
          name={FilterName.RESIDUAL_RISK}
        />
      </>
    )}
    {config.receptor && <ReceptorFilterDropdown />}
    {config.serviceRole && <ServiceRoleFilterDropdown />}
    {config.riskTier && <RiskTierFilterDropdown />}
    {config.attestation && <AttestationFilterDropdown />}
    {config.smartChecks && <SmartChecksFilterDropdown />}
    {config.source && <SourceFilterDropdown />}
    {config.evidenceType && <EvidenceTypeFilterDropdown />}
    {config.dateRange && <DateRangeFilter />}
    {config.control && <ControlFilterDropdown />}
    <ClearAllFilters />
  </FilterBarWrapper>
)
