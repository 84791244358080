import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { AUDIT_SCAN_FREQUENCY_STATUS } from 'src/components/Reusable/StatusIndicator/StatusIndicator.constants'

export type AccountSettingsConfigType = {
  title: string
  button: JSX.Element
}

export type DateLimitValue = {
  title: DateLimitOptions
  dateLabel: string
  dateValue: Timestamp | null
  isRecommended: boolean
}

export type AuditScanFrequencyConfigType = {
  label: AuditScanFrequencyLabels
  seconds: number
  status: AUDIT_SCAN_FREQUENCY_STATUS
  description?: string
  isRecommended?: boolean
}

export type DateLimitRecommendations = {
  initialValue: DateLimitOptions
  recommendations: DateLimitConfigType
}

export type DateLimitConfigType = {
  [key in DateLimitOptions]: DateLimitValue | null
}

export enum AccountSections {
  DATE_LIMIT = 'Date Limit',
  IMAGE_TIMESTAMP_VALIDATION = 'Image Timestamp Validation',
  AUDIT_SCAN_FREQUENCY = 'AI Examination and Test Frequency',
  GOOGLE_DRIVE = 'Google Drive',
  ANECDOTES = 'Anecdotes',
}

export enum DateLimitOptions {
  ACCOUNT_CREATION = 'Account Creation',
  EARLIEST_OPEN_AUDIT = 'Start of Earliest Open Audit',
  CURRENT_BOUNDARY = 'Current Boundary',
  SIX_MONTHS_AGO = 'Six Months Ago Today',
}

export const CUSTOM_DATE_LIMIT_OPTION = 'Custom' as DateLimitOptions

export enum AuditScanFrequencyOptions {
  MANUAL_ONLY = 0,
  EVERY_FOUR_HOURS = 1,
  EVERY_TWELVE_HOURS = 2,
  DAILY = 3,
  WEEKLY = 4,
  BIWEEKLY = 5,
  MONTHLY = 6,
}

export enum AuditScanFrequencyLabels {
  MANUAL_ONLY = 'Manual only, no automatic checks. Receptors pull evidence weekly.',
  EVERY_FOUR_HOURS = 'Every 4 hours',
  EVERY_TWELVE_HOURS = 'Every 12 hours',
  DAILY = 'Every day',
  WEEKLY = 'Every week',
  BIWEEKLY = 'Every other week',
  MONTHLY = 'Every month',
}

export const CUSTOM_DATE_LIMIT_VALUE = {
  title: CUSTOM_DATE_LIMIT_OPTION,
  dateLabel: 'Select a date',
  dateValue: null,
  isRecommended: false,
}

export const INITIAL_DATE_LIMIT_CONFIG: DateLimitConfigType = {
  [DateLimitOptions.ACCOUNT_CREATION]: null,
  [DateLimitOptions.EARLIEST_OPEN_AUDIT]: null,
  [DateLimitOptions.CURRENT_BOUNDARY]: null,
  [DateLimitOptions.SIX_MONTHS_AGO]: null,
}

export const DATE_LIMIT_ERROR_MESSAGE =
  'Sorry, there was an error setting the date limit. Please try again.'

export const AUDIT_SCAN_FREQUENCY_ERROR_MESSAGE =
  'Sorry, there was an error updating your AI Examination and Test frequency. Please try again.'

export const VALIDATE_IMAGE_TIMESTAMP_ERROR_MESSAGE =
  'Sorry, there was an error setting the image validation. Please try again.'
export const DATE_LIMIT_UPDATE_MESSAGE = 'updated date limit'

export const DATE_LIMIT_BANNER_MESSAGE =
  'The date limit was updated recently. This affects what you see in the Compliance Roadmap.'

export const DATE_LIMIT_DESCRIPTION =
  'Changing this date will affect which evidence Trustero AI considers.'

export const AUDIT_SCAN_FREQUENCY_DESCRIPTION =
  'How often should we examine and test your controls for compliance?'

export const AUDIT_SCAN_FREQUENCY_OPTION_TO_LABEL: Record<
  AuditScanFrequencyOptions,
  AuditScanFrequencyLabels
> = {
  [AuditScanFrequencyOptions.MANUAL_ONLY]: AuditScanFrequencyLabels.MANUAL_ONLY,
  [AuditScanFrequencyOptions.EVERY_FOUR_HOURS]:
    AuditScanFrequencyLabels.EVERY_FOUR_HOURS,
  [AuditScanFrequencyOptions.EVERY_TWELVE_HOURS]:
    AuditScanFrequencyLabels.EVERY_TWELVE_HOURS,
  [AuditScanFrequencyOptions.DAILY]: AuditScanFrequencyLabels.DAILY,
  [AuditScanFrequencyOptions.WEEKLY]: AuditScanFrequencyLabels.WEEKLY,
  [AuditScanFrequencyOptions.BIWEEKLY]: AuditScanFrequencyLabels.BIWEEKLY,
  [AuditScanFrequencyOptions.MONTHLY]: AuditScanFrequencyLabels.MONTHLY,
}

export const AUDIT_SCAN_LABEL_TO_OPTION: Record<
  AuditScanFrequencyLabels,
  AuditScanFrequencyOptions
> = {
  [AuditScanFrequencyLabels.MANUAL_ONLY]: AuditScanFrequencyOptions.MANUAL_ONLY,
  [AuditScanFrequencyLabels.EVERY_FOUR_HOURS]:
    AuditScanFrequencyOptions.EVERY_FOUR_HOURS,
  [AuditScanFrequencyLabels.EVERY_TWELVE_HOURS]:
    AuditScanFrequencyOptions.EVERY_TWELVE_HOURS,
  [AuditScanFrequencyLabels.DAILY]: AuditScanFrequencyOptions.DAILY,
  [AuditScanFrequencyLabels.WEEKLY]: AuditScanFrequencyOptions.WEEKLY,
  [AuditScanFrequencyLabels.BIWEEKLY]: AuditScanFrequencyOptions.BIWEEKLY,
  [AuditScanFrequencyLabels.MONTHLY]: AuditScanFrequencyOptions.MONTHLY,
}

export const AUDIT_SCAN_FREQUENCY_SECONDS: Record<
  AuditScanFrequencyOptions,
  number
> = {
  [AuditScanFrequencyOptions.MANUAL_ONLY]: 0,
  [AuditScanFrequencyOptions.EVERY_FOUR_HOURS]: 14400,
  [AuditScanFrequencyOptions.EVERY_TWELVE_HOURS]: 43200,
  [AuditScanFrequencyOptions.DAILY]: 86400,
  [AuditScanFrequencyOptions.WEEKLY]: 604800,
  [AuditScanFrequencyOptions.BIWEEKLY]: 1209600,
  [AuditScanFrequencyOptions.MONTHLY]: 2419200,
}

export const ACCOUNT_SETTINGS_GRID_TEMPLATE = 'max-content 1fr'
