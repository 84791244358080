import React from 'react'
import { NoResults } from 'src/components/PageLayout/NoResults'
import { NoFilterResults } from 'src/components/PageLayout/NoFilterResults'
import { NoServicesIcons } from 'src/components/Icons/EmptyStateIcons/NoServicesIcons'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { ModalFormId } from 'src/components/ModalForms'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import { Service } from 'src/xgenerated/service'
import { ServicesGrid } from '../grid/ServicesGrid'
import { AddServicesModal } from '../modals/AddServicesModal'
import { AddServiceRolesModal } from '../modals/AddServiceRolesModal'
import { ServicesProvider } from '../context/ServicesContext'
import { CreateCustomServiceModal } from '../modals/CreateCustomServiceModal'

export const ServicesIndexGrid = ({
  services,
  isLoading,
  totalServices,
}: {
  services: Service[]
  isLoading: boolean
  totalServices: number
}): JSX.Element => {
  if (isLoading) {
    return <TableListLoader />
  }

  return (
    <>
      {totalServices === 0 ? (
        <NoResults
          icons={<NoServicesIcons />}
          title="Mark which services you use."
          buttons={
            <StandardOpenModalButton
              variant={StandardButtonVariant.PRIMARY}
              size={StandardButtonSize.MEDIUM}
              modalId={ModalFormId.ADD_SERVICES}
              text="Add Services"
            />
          }
          largeTitle={true}
        />
      ) : (
        <>
          {services.length > 0 ? (
            <ServicesGrid services={services} />
          ) : (
            <NoFilterResults />
          )}
        </>
      )}
      <ServicesProvider>
        <AddServicesModal />
        <AddServiceRolesModal />
        <CreateCustomServiceModal />
      </ServicesProvider>
    </>
  )
}
