import React from 'react'
import { BannerType, useBannerContext } from 'src/context/Banner/BannerContext'
import { Banner } from './Banner'

export const AccountPurposeBanner = ({
  noMargin,
}: {
  noMargin?: boolean
}): JSX.Element => {
  const { banners } = useBannerContext()
  const accountPurposeBanner = banners[BannerType.ACCOUNT_PURPOSE]
  const showAccountPurposeBanner = accountPurposeBanner?.showBanner

  if (!showAccountPurposeBanner) return <></>

  return (
    <Banner
      noMargin={noMargin}
      text={accountPurposeBanner.message}
      isWarning={accountPurposeBanner.isWarning}
    />
  )
}
