import {
  Control,
  Controls,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { Department } from '@trustero/trustero-api-web/lib/model/department_pb'
import {
  PolicyRecord,
  PolicyRecords,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import {
  SuggestedEvidenceRecord,
  SuggestedTestRecord,
} from '@trustero/trustero-api-web/lib/recommend/recommend_pb'
import { ComplianceFrameworks } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import {
  Framework,
  Frameworks,
} from '@trustero/trustero-api-web/lib/model/framework_pb'
import FileSaver from 'file-saver'
import * as CSV from 'csv-string'
import {
  AuditReadinessRecord,
  AuditReadinessTestRecord,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import {
  getReasonText,
  getSingleCheckResultText,
} from 'src/pages/AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.helpers'
import { ResponsibilityOptions } from 'src/components/PageLayout/ShowPage/Dropdowns/Dropdowns.constants'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { controlStatusTitle } from '../../lib/common/types'
import { NONE_NAME } from '../globalConstants'
import { getFrameworkGroups } from '../globalHelpers'
import { ComplianceFrameworkGroup } from '../globalTypes'

const CONTROLS_CSV_HEADERS = [
  'Control ID',
  'Control Name',
  'Objective',
  'Notes',
  'Procedure',
  'Policy',
  'Department',
  'Status',
  'Responsibility',
  'Reason',
  'Vendor',
  'Assignee',
  'Required Evidence',
  'Test Procedures',
]

const nameResult = (name: string) => `${name} Result`
const nameReason = (name: string) => `${name} Reason`

const generateControlsReport = async (
  controls: Control[],
  policies: Record<string, PolicyRecord>,
  departments: Record<string, Department>,
  vendors: Record<string, VendorRecord>,
  frameworkGroups: Record<string, ComplianceFrameworkGroup>,
  suggestedEvidence: Record<string, string>,
  suggestedTests: Record<string, string>,
  controlTests?: Record<string, AuditReadinessTestRecord.AsObject[]>,
): Promise<Blob> => {
  const sortedCfNames = Object.keys(frameworkGroups).sort(
    (a: string, b: string) => a.localeCompare(b),
  )
  const testHeaders: Set<string> = controlTests
    ? Object.values(controlTests).reduce((set, list) => {
        list.forEach((test) => {
          set.add(nameResult(test.testName))
          set.add(nameReason(test.testName))
        })
        return set
      }, new Set<string>())
    : new Set<string>()

  const updatedHeaders = [
    ...CONTROLS_CSV_HEADERS,
    ...sortedCfNames,
    ...Array.from(testHeaders),
  ]

  const data = controls.map((control: Control) => {
    const department = departments[control.getDepartmentId()]
    const frameworkIds = new Set(control.getFrameworkIdsList())
    const controlId = control.getId()

    const row = [
      control.getModelId(),
      control.getName().trim(),
      control.getObjective().replace('##', '').trim(),
      control.getNote().trim(),
      control.getDescription().trim(),
      policies[control.getPolicyId()]?.getName() || NONE_NAME,
      department?.getName() || NONE_NAME,
      controlStatusTitle[control.getStatus()],
      ResponsibilityOptions[control.getResponsibility()],
      control.getNotApplicableReason(),
      vendors[control.getVendorId()]?.getName() || '',
      control.getOwnerEmail(),
      suggestedEvidence[control.getId()],
      suggestedTests[control.getId()],
    ]

    sortedCfNames.forEach((cfName: string) => {
      const frameworks = frameworkGroups[cfName].objectives
        .filter((objective: Framework) =>
          frameworkIds.has(objective.getModelId()),
        )
        .map((objective: Framework) => objective.getName().split(' ')[0] ?? '')
        .join(',')
      row.push(frameworks)
    })
    const tests =
      controlTests && controlId in controlTests ? controlTests[controlId] : []

    // add columns for each test header
    Array.from(testHeaders).forEach((_) => row.push(''))

    tests.forEach((check) => {
      const resultIdx = updatedHeaders.findIndex(
        (elem) => elem === nameResult(check.testName),
      )
      const reasonIdx = updatedHeaders.findIndex(
        (elem) => elem === nameReason(check.testName),
      )

      row[resultIdx] = getSingleCheckResultText(check.result)
      row[reasonIdx] = getReasonText(check.reason)
    })

    return row
  })
  const stringHeader = [updatedHeaders.join(',')]
  const csvRows = stringHeader.concat(CSV.stringify(data)).join('\r\n')
  return new Blob([csvRows], { type: 'text/csv;charset=utf-8;' })
}

export const DownloadControlsReport = async (
  controls: Controls | undefined,
  policies: PolicyRecords | undefined,
  departments: Department[] | undefined,
  vendors: VendorRecord[] | undefined,
  complianceFrameworks: ComplianceFrameworks | undefined,
  cfObjectives: Frameworks | undefined,
  suggestedEvidence: SuggestedEvidenceRecord[] | undefined,
  suggestedTests: SuggestedTestRecord[] | undefined,
  controlCheckData?: AuditReadinessRecord[],
): Promise<void> => {
  const ctrls = controls?.getItemsList() || []
  const policiesMap = (policies?.getItemsList() || []).reduce(
    (acc: Record<string, PolicyRecord>, policy: PolicyRecord) => {
      acc[policy.getModelId()] = policy
      return acc
    },
    {},
  )
  const deptMap = (departments || []).reduce(
    (acc: Record<string, Department>, dept: Department) => {
      acc[dept.getId()] = dept
      return acc
    },
    {},
  )

  const frameworkGroups = getFrameworkGroups(complianceFrameworks, cfObjectives)

  const suggestedEvidenceMap = (suggestedEvidence || []).reduce(
    (acc: Record<string, string>, record: SuggestedEvidenceRecord) => {
      if (record.getBody().length === 0) return acc
      acc[record.getControlId()] = record.getBody()
      return acc
    },
    {},
  )

  const suggestedTestsMap = (suggestedTests || []).reduce(
    (acc: Record<string, string>, record: SuggestedTestRecord) => {
      if (record.getBody().length === 0) return acc
      acc[record.getControlId()] = record.getBody()
      return acc
    },
    {},
  )

  const controlTestsMap = (controlCheckData || []).reduce(
    (
      acc: Record<string, AuditReadinessTestRecord.AsObject[]>,
      record: AuditReadinessRecord,
    ) => {
      acc[record.getControlId()] = record
        .getControlTestsList()
        .map((ele) => ele.toObject())

      return acc
    },
    {},
  )

  const vendorsMap = (vendors || []).reduce(
    (acc: Record<string, VendorRecord>, vendor: VendorRecord) => {
      acc[vendor.getId()] = vendor
      return acc
    },
    {},
  )

  const csv = await generateControlsReport(
    ctrls,
    policiesMap,
    deptMap,
    vendorsMap,
    frameworkGroups,
    suggestedEvidenceMap,
    suggestedTestsMap,
    controlTestsMap,
  )
  await FileSaver.saveAs(
    csv,
    controlCheckData ? 'controls-with-checks.csv' : 'control-detail.csv',
  )
}
